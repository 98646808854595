import HTTP from "@/api/index.js";
import { useSearchStore } from "./search";
// import { storeAdvancedSearchPreferences } from "./search";
import { initializeApp } from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import {
  getFirestore,
  // getDoc,
  // doc,
  // setDoc,
  getDocs,
  collection,
  // onSnapshot,
} from "firebase/firestore";
import {
  deleteUser,
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification, // Import sendEmailVerification
  EmailAuthProvider, reauthenticateWithCredential
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// CREATE ACCOUNT

export const fbCreateAccount = async (
  // first,
  // last,
  email,
  password

) => {
  const searchStore = useSearchStore();
  const response = await createUserWithEmailAndPassword(auth, email, password);
  try {
    await searchStore.storeAdvancedSearchPreferences(response.user.uid);
  } catch (error) {
    console.error('Error deleting account:', error);
    // Handle account deletion error appropriately
    return; // Exit if there was an error deleting the account
  }
  const userConsent = {
    userIdentifier: response.user.uid,
    acceptConsentTermsAndConditionsDataPrivacy: true
  }
  try {
    const response = await HTTP.post("/user-consent/", userConsent, {
      headers: {
        'x-api-key': process.env.VUE_APP_API_ENDOPOINTS_KEY
      }
    })
    if (response.status === 200) {
      console.log("Success!")
    }
  } catch (error) {
    console.log(error)
  }
  // Send email verification
  if (response.user) {
    await sendEmailVerification(response.user);
  }
  // // SET USER PROFILE: Disbaled for now. Dont wanna user firestore only to store name and lastname yet. Same with GET USER PROFILE
  // if (response) {
  //   await fbSetUserProfile({ first, last, age: 67 });
  //   const profile = await fbGetUserProfile();
  //   return {
  //     user: response.user,
  //     profile,
  //   };
  // } else {
  //   return {
  //     user: null,
  //     profile: null,
  //   };
  // }
};

// SIGN IN
export const fbSignIn = async (email, password) => {
  const response = await signInWithEmailAndPassword(auth, email, password);

  // Check if the user has verified their email
  if (!response.user.emailVerified) {
    // Handle the case where the user's email is not verified.
    // You could throw an error or return a custom response indicating the issue.
    // ToDo: Make an ionic alert.
    alert('Please verify your email before signing in.')
  }
  // console.log(response)
  return response; // Or return a custom object indicating a successful sign-in.
};

// SIGN OUT
export const fbSignOut = async () => {
  await signOut(auth);
  return true;
};

// AUTH STATE LISTENER
export const fbAuthStateListener = (callback) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      callback(user);
    } else {
      // User is signed out
      callback(null);
    }
  });
};

// SET USER PROFILE
// export const fbSetUserProfile = async ({
//   first,
//   last,
//   age }) => {
//   const user = auth.currentUser;
//   console.log(user);

//   const ref = doc(db, "profiles", user?.uid);
//   await setDoc(
//     ref,
//     {
//       first,
//       last,
//       age,
//       uid: user?.uid,
//     },
//     { merge: true }
//   );
//   return true;
// };

// GET USER PROFILE
// export const fbGetUserProfile = async () => {
//   const user = auth.currentUser;
//   console.log(user);

//   const ref = doc(db, "profiles", user?.uid);
//   const docSnap = await getDoc(ref);

//   if (docSnap.exists()) {
//     console.log("Document data:", docSnap.data());
//     return {
//       ...docSnap.data(),
//       uid: user?.uid,
//     };
//   } else {
//     // doc.data() will be undefined in this case
//     console.log("No such document!", user?.uid);
//     return null;
//   }
// };


// QUERY OBJECT COLLECTION
export const queryObjectCollection = async ({
  collectionName,
}) => {
  const querySnapshot = await getDocs(collection(db, collectionName));
  const results = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });
  return results;
};

// Do we need this?
// /**
//  * 
//  * @param collectionName 
//  * @param callback 
//  */
// export const fbCollectionListener = (collectionName: string, callback: any) => {
//   const unsubscribe = onSnapshot(
//     collection(db, collectionName),
//     (snapshot) => {
//       // ...
//       console.log("Listening To Collection: " + collectionName, snapshot);
//       const results: any[] = [];
//       snapshot.forEach((doc) => {
//         results.push({
//           id: doc.id,
//           ...doc.data(),
//         });
//       });
//       callback(results);
//     },
//     (error) => {
//       // ...
//       console.log("Error Listening To Collection: " + collectionName, error);
//     }
//   );
// };
// Function to re-authenticate the user
export const reauthenticateUser = async (email, password) => {
  const user = auth.currentUser;
  const credential = EmailAuthProvider.credential(email, password);

  try {
    await reauthenticateWithCredential(user, credential);
    // console.log('User re-authenticated successfully.');
    return true; // Re-authentication succeeded
  } catch (error) {
    // console.error('Error during re-authentication:', error);
    return false; // Re-authentication failed
  }
};

// DELETE ACCOUNT
export const fbDeleteAccount = async () => {
  const user = auth.currentUser; // Get the currently signed-in user

  if (user) {
    try {
      await deleteUser(user);
      // console.log("User account deleted successfully.");
      // Optionally, perform any clean-up operations here (e.g., redirecting the user, updating UI)
    } catch (error) {
      console.error("Error deleting user account:", error);
      // Handle errors, such as lack of internet connection or insufficient permissions
      // You may also need to re-authenticate the user if they've been signed-in for too long
    }
  } else {
    console.log("No user is currently signed in.");
  }
};
export { app, db, auth };